import React, { useEffect } from 'react';
import '../Assets/Additionalcss/Button.css'
function Arrow(){
  
           
  function top() {
   
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      
    });
   
   var mybutton = document.getElementById("myBtn");
    window.onscroll = function() {scrollFunction()};
  
     function scrollFunction() {
        if (document.body.scrollTop >20|| document.documentElement.scrollTop >20)
         {
          mybutton.style.display = "block";
          }
           else
            {
              mybutton.style.display = "none";
            }
        }
        
      }
      useEffect(() => {
        var mybutton = document.getElementById("myBtn");
          mybutton.style.display = "none";
        window.onscroll = function() { 
        if (document.body.scrollTop >20|| document.documentElement.scrollTop >20)
         {
          mybutton.style.display = "block";
          }
           else
            {
              mybutton.style.display = "none";
            }
        };
      } ); 


return(
    <div>
        <button  onClick={() =>top()} id="myBtn" ><i className="fas fa-angle-double-up"></i></button>
    </div>
)
}
export default Arrow;