import React, { useEffect, useState } from 'react';
import Footer from '../Components/Footer';
import { BASE_URL } from '../Config/Base'
import { Image_URL } from '../Config/Base'
import Header from '../Components/Header';
import { useLocation, useParams, useNavigate, generatePath } from "react-router-dom";
import Axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function DistrictEvent() {
    const navigate = useNavigate();
    const filtertext = useLocation();
    const [data, setData] = useState([])
    const [griddata, setGriddata] = useState([])
    const location = useLocation();
    const main_loc = location.state.main_location;
    function back() {

      if(filtertext.length == 0)
      {
        navigate("/Journey", { state: { eletter: filtertext.state.eletter} });
      }
      else
      {
        navigate("/Journey");
      }
    }
    const handleProceed = (id, loc) => {
        navigate(generatePath("/Newseemore/:id", { id }), { state: { eMlocation: loc } });
      };

      const seemorehandle = (id, loc) => {
        navigate(generatePath("/Seemorelist/:id", { id }), { state: { eMlocation: loc } });
      };

useEffect(() =>{
    Axios({
        method: "GET",
        url: `${BASE_URL}/Foundation/getDistrictSort?event_main_location=${main_loc}`
      }).then((resp) => {
        jj(resp.data)
        setGriddata(resp.data)
      })
}, [])

const jj = (ss) => {
    const newArray = [];
    const uniqueObject = {};
    for (const i in ss) {
      const objTitle = ss[i]['event_location'];
      uniqueObject[objTitle] = ss[i];
    }
    for (const i in uniqueObject) {
      newArray.push(uniqueObject[i]);
    }
    setData(newArray)
  }

    return (
        <div>
           <Header />
           <div className="Journey-container">
           <div className="Journey-box-district" >
           <p className="backoption" onClick={back}><i className="fas fa-caret-left"></i> Back</p>
            <div className="grid-contain">
          {data.length == 0 ? <div className="journeycard">
                <div className="card-body">
                  <h6 className="card-title"><b>No Event</b></h6>
                </div>
              </div> : data.map((item, d) => {
            return <>{item.event_location == null ? null : <div className="textcard" onClick={(e) => {
              handleProceed(item.Id);
            }} key={d}>{item.event_location}</div>} 
              </>
          })}
          
        </div>
      </div>

      <div className="container">
<div className="row">
{griddata.map((item, a) => {
   return   <div className="journeycard" key={a}>
          <div className="journey-date">
            <p className="journey-dat">
              <span className="journey-da">{new Date(item.event_date).getDate()}</span><br />{new Date(item.event_date).toLocaleString('default', { month: 'short' })}</p>
          </div>
          {/* <img className="card-img" src={`https://ouradmins.coolboiler.com//${item.image_path}`} /> */}
          <LazyLoadImage
                className="card-img"
    effect="blur"
    src={`${Image_URL}${item.image_path}`} />
          <div className="card-body">
            <h6 className="card-title"><b>{item.event_name}</b> at {item.event_place}</h6>
            <div onClick={(e) => {
                seemorehandle(item.Id, item.event_name)
              }}>See More</div>
          </div>
        </div>
})}
      
      </div>
      </div>
      </div>


      



           <Footer /> 
        </div>
    );
}

export default DistrictEvent;