import React, { useState, useEffect} from 'react';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import Moment from 'react-moment';
import '../Assets/Additionalcss/Seemore.css'
import vidone from '../Assets/Images/vid1.mp4'
import vidtwo from '../Assets/Images/vid2.mp4'
import vidthree from '../Assets/Images/vid3.mp4'
import { useLocation, useParams, useNavigate, generatePath } from "react-router-dom";
import Axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Slide from 'react-reveal/Slide';
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import ReactPlayer from 'react-player'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Snackbar from '@mui/material/Snackbar';
import {BASE_URL} from '../Config/Base'
import {Image_URL} from '../Config/Base'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Particularevent() {
    const { id } = useParams();
  const filtertext = useLocation();
  const navigate = useNavigate();
  let formData = new FormData();
  const [Gallery, setGallery] = useState([])
  const [jour, setJourney] = useState([])
  const [listdisp, setListdisp] = useState('')
  const [filtdata, setfiltdata] = useState([])
  const [imavid, setImavid] = useState(false)
  const [Load, setLoad] = useState(true)
  const [letter, setLetter] = useState('')
  const [imgdisp, setImgdisp] = useState()
  const [viddisp, setviddisp] = useState(vidone)
  const [data, setData] = useState([])
  const [com, setCom] = useState([])
  const [comment, setcomment] = useState(true)
  const [inputname, setInputName] = useState(true)
  const [inputcomment, setInputComment] = useState(true)
  const location = useLocation();
  const state = location.state;
  const [postcom, setPostcom] = useState({
    event_id: state,
    user_name: "",
    comment_desc: "",
  })

  const url = window.location.href;
  const message = 'Hey there I am share event to you'
  const facebookapi = `https://www.facebook.com/sharer.php?u=${url}`
  const whatsappapi = `https://wa.me/?text=${url}&text=${message}`
  const twitterapi = `https://twitter.com/intent/tweet?text=${url}.${message}`

  function display() {
    setImavid(!imavid)
  }

  function back() {
    navigate("/Journey", { state: { eletter: filtertext.state.eletter} });
  }

  const handleProceed = (id) => {
    navigate(generatePath("/Seemorelist/:id", { id }));
    window.location.reload();
  };

  useEffect(() => {
    Axios({
      method: "GET",
      url: `${BASE_URL}/Foundation/getParticular?Id=${id}`
    }).then((resp) => {
      setData(resp.data)
      dd(resp.data)
      setLoad(false)
      setLetter(resp.data.map((e) => e.event_place.slice(0, 1)))
    })

    Axios({
      method: "GET",
      url: `${BASE_URL}/Foundation/getComment?Id=${id}`
    }).then((resp) => {
      setCom(resp.data)
    })
    // setTimeout(() => {
    //   setLoad(false)
    // }, 1000);

    Axios({
      method: "GET",
      url: `${BASE_URL}/Foundation/getJourney`
    }).then((resp) => {
      setJourney(resp.data)
    })
    Axios({
      method: "GET",
      url: `${BASE_URL}/Foundation/getEventsGalleries?id=${id}`
    }).then((resp) => {
      setGallery(resp.data)
      Galleryimage(resp.data)
    })

    
  }, [])

  const Galleryimage = (ss) => {
    const gi = ss.map((e) => e.image_path)
    setImgdisp(gi[0])
  }

  
  const dd = (ss) => {
    const ename = ss.map((e) => e.event_name)
    const eplace = ss.map((e) => e.event_place)
    const eid = ss.map((e) => e.Id)
Axios({
      method: "GET",
      url: `${BASE_URL}/Foundation/getDisplaysamedata?&id=${eid}&event_name=${ename}&event_place=${eplace}`
    }).then((resp) => {
setfiltdata(resp.data)
    })

    // const wdata = ss.filter((e) => {
    //   return e.event_name.includes('School')
    // }) 
    // setListdisp(wdata.length)

   
  }

  function validation() {
    formData.append('event_id', postcom.event_id)
    formData.append('user_name', postcom.user_name)
    formData.append('comment_desc', postcom.comment_desc)
    let un = document.getElementById('user_name').value
    let uc = document.getElementById('comment_desc').value
    if (un.length == "" || un == null) {
      alert('Enter Your Name')
    }
    else if (uc.length == "" || uc == null) {
      alert('Give Your Comment')
    }
    else {
      Axios.post(`${BASE_URL}/Foundation/AddComment`, formData)
        .then(res => {
          if (res.data == "Comment Added Successfully") {
            window.location.reload(false);
          }
        })

    }
  }

  function store(e) {
    const newdata = { ...postcom }
    newdata[e.target.id] = e.target.value
    setPostcom(newdata)
    e.preventDefault()
    let un = document.getElementById('user_name').value
    let uc = document.getElementById('comment_desc').value
    if (un.length == "" || un == null) {
      setInputName(false)
    }
    else if (uc.length == "" || uc == null) {
      setInputComment(!inputcomment)
    }
  }

  return (
    <div>
      <Header />
      <div className="seemore-container">
        <div className="social-share-container">
          <div className="facebook" onClick={() => window.open(facebookapi)}>
            <i className="fab fa-facebook" title="Facebook"></i>
          </div>
          <div className="whatsapp" onClick={() => window.open(whatsappapi)}>
            <i className="fab fa-whatsapp" title="Whatsapp"></i>
          </div>
          <div className="twitter" onClick={() => window.open(twitterapi)}>
            <i className="fab fa-twitter" title="Twitter"></i>
          </div>
          <div className="urlcopy" onClick={() => toast.info("Url has been copied")}>
            <CopyToClipboard text={url}>
              <i className="fas fa-copy" title="Copy Url"></i>
            </CopyToClipboard>
          </div>
        </div>
        <div className="seemore-card">
        <p className="backoption" onClick={back}><i class="fas fa-caret-left"></i> Back</p>
        {data.map((item) =>{
              return<div className="story">
                <h5 className="seemore-event">{item.event_name} at {item.event_place} on {new Date(item.event_date).getDate()}-{new Date(item.event_date).toLocaleString('default', { month: 'short' })}-{new Date(item.event_date).getFullYear()}</h5>
              </div>
            })}
          <div className="seemore-option-IV">
            <div className="option-IV" onClick={() => setImavid(false)}>Images</div>
            {/* <div className="option-IV" onClick={() => setImavid(true)}>Videos</div> */}
          </div>
{Load == true ? <div className="seemore-skeleton">
            <Stack spacing={15}>
              <div className="skeleton">
                <div className="skeleton-div">
                  <div className="skeleton-contain">
                    <div className="skeleton-row">
                      <Skeleton className="width-height" variant="rectangular" height={350} />
                    </div>
                    <div className="skeleton-col">
                      <Skeleton className="skelet" variant="rectangular" height={150} width={200} />
                      <Skeleton className="skelet" variant="rectangular" height={150} width={200} />
                      <Skeleton className="skelet" variant="rectangular" height={40} width={200} />
                    </div>
                  </div>
                </div>
              </div>
            </Stack>
          </div>
 : 

          imavid == false ?

              <div className="seemare-img-section">
                <div className="contain-images">
                  <div className="main-picture">
                    <div className="picture">
                    {/* <LazyLoadImage
                className="pic-disp"
    effect="blur"
    src={`${Image_URL}${imgdisp}`} /> */}

                      <img className="pic-disp" src={`${Image_URL}${imgdisp}`} />
                    </div>
                  </div>

                  <div className="picture-list">
                    {Gallery.map((item) => {
                    return <div onClick={() => setImgdisp(item.image_path)} className={imgdisp == item.image_path ? 'pic active' : 'pic'} >
                 

                      <LazyLoadImage
                className="pic-disp"
    effect="blur"
    src={`${Image_URL}${item.image_path}`} />
                    </div>
                    })}
                  </div>
                </div>
              </div>

              :
              <div className="seemare-img-section">
                <div className="contain-images">
                  <div className="main-picture">
                    <div className="picture">
                      <ReactPlayer className="pic-disp" url={viddisp} controls={true} width="450px" height="300px" />
                    </div>
                  </div>
                  <div className="picture-list">
                    <div onClick={() => setviddisp(vidone)} className={viddisp == vidone ? 'pic active' : 'pic'} >
                      <video src={vidone} className="pic-disp" />
                    </div>
                    <div onClick={() => setviddisp(vidtwo)} className={viddisp == vidtwo ? 'pic active' : 'pic'}>
                      <video src={vidtwo} className="pic-disp" />
                    </div>
                    <div onClick={() => setviddisp(vidthree)} className={viddisp == vidthree ? 'pic active' : 'pic'}>
                      <video src={vidthree} className="pic-disp" />
                    </div>
                  </div>
                </div>
              </div>
            }
{data.map((item) =>{
              return<div className="story">
                <p className="seemore-story">{item.story}</p>
              </div>
            })}          

          <div className="comment-section-container">
            <input type="button" className={comment == false ? "seemore-comment-btn-clicked" : "seemore-comment-btn"} value={comment == false ? "Back" : "Leave a comment"} onClick={comment == false ? () => setcomment(true) : () => setcomment(false)} />
            {comment == true ?
              <>
                {com.length == 0 ? <p>No Comments</p> : com
                  .map((item, r) => {
                    return <div className="comment" key={r}>
                      <div className="comment-flex">
                        <Avatar sx={{ bgcolor: 'green' }}>{item.user_name.split(' ')[0][0]}</Avatar>
                        <p className="comment-name">{item.user_name}</p>
                      </div>
                      <p className="comment-date"><Moment fromNow>{item.created_DateTime}</Moment></p>
                      <p className="comment-desc">{item.comment_desc}</p>
                    </div>
                  })}
              </>
              :
              <>
                <Slide bottom >
                  <form>
                    <input name="user_name" id="user_name" autoComplete="off" className="comment-input" type="text" placeholder="Enter Your Name" onChange={(e) => store(e)} /> <br />
                    <textarea name="comment_desc" id="comment_desc" autoComplete="off" className="comment-input" type="text" placeholder="Comment Here" onChange={(e) => store(e)} /> <br />
                    <div className="">
                      <input type="button" className="button-37" value="Submit" onClick={(e) => validation(e)} />
                    </div>
                  </form>
                </Slide>
              </>
            }
          </div>
        </div>

 {/* {listdisp == 0 ? null : */}
 <div className="optiongrid">
          {filtdata.filter((item) => {
            if (letter == 0) {
              return item
            }
            else if (letter == item.event_place.slice(0, 1)) {
              return item
            }
          }).length == 0 ? null : filtdata.filter((item) => {
            if (letter == 0) {
              return item
            }
            else if (letter == item.event_place.slice(0, 1)) {
              return item
            }
          }).map((item, e) => {
            return <>
              <div className="seemore-option2" onClick={(e) => {
                handleProceed(item.Id, item.event_name)
              }}>{new Date(item.event_date).getFullYear()}</div>
            </>
          })}
        </div>
{/* } */}
        
      </div>
        
      <Footer />
      <ToastContainer position="top-left" autoClose={100} hideProgressBar newestOnTop={false} closeOnClick={false} rtl={false} pauseOnFocusLoss={false} draggable={false} pauseOnHover={false} />
    </div>
  );
}
export default Particularevent;