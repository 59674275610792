import React from 'react';
import { Carousel } from 'react-bootstrap';
import kdp1 from '../Assets/Images/kdpslide/kdp1.jpg';
import kdp2 from '../Assets/Images/kdpslide/kdp2.jpg';
import kdp3 from '../Assets/Images/kdpslide/kdp3.jpg';
import kdp4 from '../Assets/Images/kdpslide/kdp4.JPG';
import kdp5 from '../Assets/Images/kdpslide/kdp5.JPG';
import kdp6 from '../Assets/Images/kdpslide/kdp6.JPG';
import kdp7 from '../Assets/Images/kdpslide/kdp7.JPG';

function ImgCarousel() {
  return (
    <Carousel fade={true} pause={false} controls={false} prevLabel={false} nextLabel={false} indicators={false}>
      <Carousel.Item interval={3000}>
          <div className="Starting-swiper-slide">
            <div className="Starting-container">
            <div className="Starting-text-container">
            <p className="Starting-text">We are keen to help people</p>
            </div>
            <img className="Starting-img" src={kdp6} alt="" />
            </div>
            </div>
      </Carousel.Item>
      
      <Carousel.Item interval={2000}>
      <div className="Starting-swiper-slide">
            <div className="Starting-container">
            <div className="Starting-text-container">
            <p className="Starting-text">Find PEACE for your mind by helping others</p>
            </div>
            <img className="Starting-img" src={kdp2} alt="" />
            </div>
            </div>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
      <div className="Starting-swiper-slide">
            <div className="Starting-container">
            <div className="Starting-text-container">
            <p className="Starting-text">Proud to be a volunteer</p>
            </div>
            <img className="Starting-img" src={kdp3} alt="" />
            </div>
            </div>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
      <div className="Starting-swiper-slide">
            <div className="Starting-container">
            <div className="Starting-text-container">
            <p className="Starting-text">Wonder happens when your smile covers someone's life</p>
            </div>
            <img className="Starting-img" src={kdp4} alt="" />
            </div>
            </div>
      </Carousel.Item>

       <Carousel.Item interval={2000}>
      <div className="Starting-swiper-slide">
            <div className="Starting-container">
            <div className="Starting-text-container">
            <p className="Starting-text"> We here for charity not business</p>
            </div>
            <img className="Starting-img" src={kdp5} alt="" />
            </div>
            </div>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
      <div className="Starting-swiper-slide">
            <div className="Starting-container">
            <div className="Starting-text-container">
            <p className="Starting-text">Your Extra, someone's Need</p>
            </div>
            <img className="Starting-img" src={kdp1} alt="" />
            </div>
            </div>
      </Carousel.Item>

     <Carousel.Item interval={2000}>
      <div className="Starting-swiper-slide">
            <div className="Starting-container">
            <div className="Starting-text-container">
            <p className="Starting-text">Selfish people! No one is. There is good in everyone!</p>
            </div>
            <img className="Starting-img" src={kdp7} alt="" />
            </div>
            </div>
      </Carousel.Item>
    </Carousel>
  )
}
export default ImgCarousel;