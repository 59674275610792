import React, { Component, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import '../Assets/Additionalcss/Home.css'
import part1 from '../Assets/Images/Beapart/Beapart1.jpg'
import part2 from '../Assets/Images/Beapart/Beapart2.JPG'
import part3 from '../Assets/Images/Beapart/Beapart3.JPG'
import location from '../Assets/Images/location.png'
import peo from '../Assets/Images/people.png'
import school from '../Assets/Images/school.png'
import state from '../Assets/Images/state.png'
import volunteer from '../Assets/Images/volunteer.png'
import Button from '../Components/Button'
import Slider from "react-slick";
import Axios from 'axios';
import Arrow from '../Components/Arrow';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/autoplay"
import { EffectCoverflow, Pagination, Autoplay, Navigation, EffectFade } from "swiper";
import D1 from '../Assets/Images/Difference/Difference_1.JPG'
import D2 from '../Assets/Images/Difference/Difference_2.jpg'
import D3 from '../Assets/Images/Difference/Difference_3.jpg'
import D4 from '../Assets/Images/Difference/Difference_4.jpg'
import D5 from '../Assets/Images/Difference/Difference_5.JPG'
import D6 from '../Assets/Images/Difference/Difference_6.jpg'
import D7 from '../Assets/Images/Difference/Difference_7.jpg'
import D8 from '../Assets/Images/Difference/Difference_8.JPG'
import D9 from '../Assets/Images/Difference/Difference_9.JPG'
import D10 from '../Assets/Images/Difference/Difference_10.JPG'
import AD from '../Assets/Images/Contributors/AD.jpg'
import BG from '../Assets/Images/Contributors/BG.jpg'
import MNB from '../Assets/Images/Contributors/MNB.jpeg'
import MS from '../Assets/Images/Contributors/MS.jpg'
import MP from '../Assets/Images/Contributors/MP.jpg'
import TS from '../Assets/Images/Contributors/TS.jpg'
import VB from '../Assets/Images/Contributors/VB.jpg'
import JK from '../Assets/Images/Contributors/JK.jpg'
import VC from '../Assets/Images/Contributors/Vc.jpg'
import SH from '../Assets/Images/Contributors/SH.jpg'
import SM from '../Assets/Images/Contributors/SM.jpeg'
import VK from '../Assets/Images/Contributors/VK.jpeg'
import AM from '../Assets/Images/Contributors/AM.jpg'
import { Image_URL } from '../Config/Base'
import "swiper/css/navigation";
import ImgCarousel from '../Components/ImgCarousel';
import Count from '../Components/Count';
import {BASE_URL} from '../Config/Base'

function Home() {
    const [data, setData] = useState([])
    const [time, setTime] = useState([])
    const settings = {
        infinite: true, speed: 500, slidesToShow: 3, slidesToScroll: 1, autoplay: true,
        responsive: [
            {
                breakpoint: 768, settings: { infinite: true, speed: 500, slidesToShow: 1, slidesToScroll: 1, autoplay: true, }
            },
        ]
    };




    useEffect(() => {
        Axios({
            method: "GET",
            url: `${BASE_URL}/Foundation/getContributors`
        }).then((resp) => {
            setData(resp.data)
        })
    }, [])

    return (
        <div>
            <Header />
            <Arrow />

            {/* Starting Carousel */}
            <div className="Starting-carousel">
                <ImgCarousel />
            </div>


            {/* Difference We have made */}
            <div className="Difference-carousel-container">
                <h3 className="futuretext">Difference We Have Made</h3>
                <div className="Difference-carousel">
                    <Slider {...settings}>
                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D1} />
                                <p className="card-school">School Name - Location</p>
                            </div>
                        </div>
                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D2} />
                                <p className="card-school">School Name - Location</p>
                            </div>
                        </div>
                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D3} />
                                <p className="card-school">School Name - Location</p>
                            </div>
                        </div>

                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D4} />
                                <p className="card-school">School Name - Location</p>
                            </div>
                        </div>

                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D5} />
                                <p className="card-school">School Name - Location</p>
                            </div>
                        </div>

                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D6} />
                                <p className="card-school">School Name - Location</p>
                            </div>
                        </div>


                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D7} />
                                <p className="card-school">GLPS Chakenahalli - Yediyur</p>
                            </div>
                        </div>


                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D8} />
                                <p className="card-school">chinthamani - Muddalahalli</p>
                            </div>
                        </div>


                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D9} />
                                <p className="card-school">School Name - Location</p>
                            </div>
                        </div>

                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D10} />
                                <p className="card-school">School Name - Location</p>
                            </div>
                        </div>
                        </Slider>
                        </div>
                        </div>

            {/* Where we have been starting */}
            <div className="countpart">
                <h3 className="futuretext">Where We Have Been</h3>
                <div className="countpart-layer">
                    <div className="countpart-container">
                        <Count endnumber={5} durationnumber={3} names="States" imaage={state} />
                        <Count endnumber={332} durationnumber={3} names="Events" imaage={school} plus="+" />
                        <Count endnumber={40000} durationnumber={5} names="Beneficiaries" imaage={peo} plus="+"/>
                        <Count endnumber={10000} durationnumber={5} names="Volunteer Hours" imaage={volunteer} plus="+"/>
                    </div>
                </div>
            </div>

            {/* Be a part future starting */}
            <div className="part-box">
                <h3 className="futuretext">Be A Part of Future</h3>
                <Swiper spaceBetween={30} centeredSlides={true} autoplay={{ delay: 2500, disableOnInteraction: false, }} pagination={{ clickable: true, }} navigation={true} modules={[Autoplay, Pagination, Navigation]} className="mySwiper">
                    <SwiperSlide>
                        <div className="part-container">
                            <div className="part-column">
                                <img src={part1} className="part-responsive" alt="" />
                                <div className="part-text-container">
                                    <h4 className="eventname">Sarkari Hiriya Prathamika Shale</h4>
                                    <h5 className="eventloc"><img className="eventimg" src={location} /> Kolar</h5>
                                    <div className="part-btn">
                                        <Link style={{ textDecoration: 'none' }} to="/Donate"><Button style="button-y" title="Lend to hand" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="part-container">
                            <div className="part-column">
                                <img src={part2} className="part-responsive" alt="" />
                                <div className="part-text-container">
                                    <h4 className="eventname">Sarkari Hiriya Prathamika Shale</h4>
                                    <h5 className="eventloc"><img className="eventimg" src={location} />  Baniga</h5>
                                    <div className="part-btn">
                                        <Link style={{ textDecoration: 'none' }} to="/Donate"><Button style="button-y" title="Lend to hand" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>


                    <SwiperSlide>
                        <div className="part-container">
                            <div className="part-column">
                                <img src={part3} className="part-responsive" alt="" />
                                <div className="part-text-container">
                                    <h4 className="eventname">Sarkari Hiriya Prathamika Shale</h4>
                                    <h5 className="eventloc"><img className="eventimg" src={location} /> Chendur</h5>
                                    <div className="part-btn">
                                        <Link style={{ textDecoration: 'none' }} to="/Donate"><Button style="button-y" title="Lend to hand" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>


            {/* Contributors starting */}
            <div className="bg-img"></div>
            <div className="Contributors-container" id="contribute" >
                <span>
                <h3 className="contribute-text">Contributors</h3>
                <Swiper effect={"coverflow"} grabCursor={true} centeredSlides={true} slidesPerView={"auto"} coverflowEffect={{ rotate: 0, stretch: 0, depth: 150, modifier: 10, slideShadows: false, }} autoplay={{ delay: 3000, disableOnInteraction: false }} modules={[EffectCoverflow, Pagination, Autoplay]} className="Contributors">
                    {/* {data.map((item, i) => {
                        return <SwiperSlide className="Contributors-slide" key={i}>
                            <div className="swipe-card">
                            
                                <img src={`https://ouradmins.coolboiler.com//Images/${item.file_name}`} />
                                <h3 className="Contributors-name">{item.Title}</h3>
                                <h3 className="Contributors-desc">{item.Description}</h3>
                            </div>
                        </SwiperSlide>
                    })} */}
                    <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">
                                <img src={AD} />
                                <h3 className="Contributors-name">Anand</h3>
                                <h3 className="Contributors-desc">Great opportunity to serve others</h3>
                            </div>
                        </SwiperSlide>
                            <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">
                                <img src={BG} />
                                <h3 className="Contributors-name">BetteGowda</h3>
                                <h3 className="Contributors-desc">No gift is small to make a difference</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">
                                <img src={MNB} />
                                <h3 className="Contributors-name">Mahesh</h3>
                                <h3 className="Contributors-desc">Giving is not just about making a donation, it’s about making a difference</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={MS} />
                                <h3 className="Contributors-name">Mallikarjuna</h3>
                                <h3 className="Contributors-desc">Smiles of others inspire me</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">
                                <img src={MP} />
                                <h3 className="Contributors-name">Murugan</h3>
                                <h3 className="Contributors-desc">Want to take society to the next level</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">
                            
                                <img src={TS} />
                                <h3 className="Contributors-name">Thippesh</h3>
                                <h3 className="Contributors-desc">I am discovering success at rural eyes</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">
                            
                                <img src={VB} />
                                <h3 className="Contributors-name">Vishwanatha</h3>
                                <h3 className="Contributors-desc">I am here to change your pessimistic thoughts in to optimistic ones</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={JK} />
                                <h3 className="Contributors-name">Kishore</h3>
                                <h3 className="Contributors-desc">Don’t worry about returns</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={VC} />
                                <h3 className="Contributors-name">Venkatesh</h3>
                                <h3 className="Contributors-desc">It always feels good to help to people</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={SM} />
                                <h3 className="Contributors-name">Sushma</h3>
                                <h3 className="Contributors-desc">The time is always right to do what is right</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={SH} />
                                <h3 className="Contributors-name">Sudha</h3>
                                <h3 className="Contributors-desc">We make a living by what we get, but we make a life by what we give</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={VK} />
                                <h3 className="Contributors-name">Vasantha Kumar</h3>
                                <h3 className="Contributors-desc">I believe in spreading happiness and positivity in my surroundings</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={AM} />
                                <h3 className="Contributors-name">Ananta Murali</h3>
                                <h3 className="Contributors-desc">Want to see smile in children's life</h3>
                            </div>
                        </SwiperSlide>

                        
                </Swiper>
                </span>
            </div>
            <Footer />
        </div>

    );
}

export default Home;