import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import '../Assets/Additionalcss/Privacy.css';
import Arrow from '../Components/Arrow';


function Privacy() {
    return (
        <div>
            <Header/>
            <Arrow />
                <div className="pri-container contain-privacy">
             <div className="main-title">Privacy Policy</div>
             <p className="privacy-content">KDP Foundation (KDPFoundation.org) recognizes the importance of maintaining your privacy. We value your privacy and appreciate your trust in us. This Policy describes how we treat user information we collect on https://www.KDPFoundation.org and other offline sources. This Privacy Policy applies to current and former visitors to our website and to our online customers. By visiting and/or using our website, you agree to this Privacy Policy. KDPFoundation.org is a property of KDP Foundation , an Indian Charitable Organization registered in 2013 having its registered office at 80, Neelanjana, 6th Main, 2nd Cross, J P Nagar 3rd Phase, Bengaluru - 560078.</p>
             <div className="privacy-title">Information we collect</div>   
             <p className="privacy-content">Contact information. We might collect your name, email, mobile number, phone number, street, city, state, pincode, country and ip address.<br/>
Information you post. We collect information you post in a public space on our website or on a third-party social media site belonging to KDPFoundation.org<br/>
Other information. If you use our website, we may collect information about your IP address and the browser you're using. We might look at what site you came from, duration of time spent on our website, pages accessed or what site you visit when you leave us. We might also collect the type of mobile device you are using, or the version of the operating system your computer or device is running.<br/>
We collect information in different ways.<br/>
We collect information directly from you. We collect information directly from you when you register on our website. We also collect information if you post a comment on our websites or ask us a question through phone or email.<br/>
We collect information from you passively. We use tracking tools like Google Analytics, Google Webmaster, browser cookies and web beacons for collecting information about your usage of our website.<br/>
We get information about you from third parties. For example, if you use an integrated social media feature on our websites. The third-party social media site will give us certain information about you. This could include your name and email address.</p> 
<div className="privacy-title">Use of your personal information</div>        
<p className="privacy-content">We use information to contact you: We might use the information you provide to contact you for confirmation for promotional purposes.<br/>
We use information to respond to your requests or questions. We might use your information to confirm your registration on our website.<br/>
We use information to improve our products and services. We might use your information to customize your experience with us. This could include displaying content based upon your preferences.<br/>
We use information to look at site trends and customer interests. We may use your information to make our website and products better. We may combine information we get from you with information about you we get from third parties.<br/>
We use information for security purposes. We may use information to protect our company, our customers, or our websites.<br/>
We use information for marketing purposes. We might send you information about special promotions or offers. We might also tell you about new features or products. These might be our own offers or products, or third-party offers or products we think you might find interesting.<br/>
We use the information to send you transactional communications. We might contact you via emails, phone or SMS (text messages) to deliver certain services or information about your account. We use information as otherwise permitted by law.</p> 
<div className="privacy-title">Sharing of information with third-parties</div> 
<p className="privacy-content">We will share information with third parties who perform services on our behalf. We share information with vendors who help us manage our online registration process or payment processors or transactional message processors. Some vendors may be located outside of India.<br/>
We will share information with our business partners. This includes a third party who provide or sponsor an event. Our partners use the information we give them as described in their privacy policies.<br/>
We may share information if we think we have to in order to comply with the law or to protect ourselves. We will share information to respond to a court order or subpoena. We may also share it if a government agency or investigatory body requests. Or, we might also share information when we are investigating potential fraud.<br/>
We may share information with any successor to all or part of our business. For example, if part of our business is sold we may give our customer list as part of that transaction. We may share your information for reasons not described in this policy. We will tell you before we do this.</p>
<div className="privacy-title">Email Opt-Out</div>
<p className="privacy-content">You can opt out of receiving our marketing emails. To stop receiving our promotional emails, please email unsubscribe@kdpfoundation.org. It may take about thirty days to process your request. Even if you opt out of getting marketing messages, we will still be sending you transactional messages through email and SMS about your content.</p>
<div className="privacy-title">Third Party sites</div>
<p className="privacy-content">If you click on one of the links to third party websites, you may be taken to websites we do not control. This policy does not apply to the privacy practices of those websites. Read the privacy policy of other websites carefully. We are not responsible for these third party sites.</p>    
<div className="privacy-title">Grievance Officer</div>
<p className="privacy-content">If you have any questions about this Policy or other privacy concerns, you can also email us at contact@kdpFoundation.org</p>      
<div className="privacy-title">Updates to this policy</div>        
<p className="privacy-content">This Privacy Policy was last updated on 01.01.2022. From time to time we may change our privacy practices. We will notify you of any material changes to this policy as required by law. We will also post an updated copy on our website. Please check our site periodically for updates.</p>
<div className="privacy-title">Jurisdiction</div>    
<p className="privacy-content">If you choose to visit the website, your visit and any dispute over privacy is subject to this Policy and the website's terms of use. In addition to the foregoing, any disputes arising under this Policy shall be governed by the laws of India.</p>   
<div className="privacy-title">Cancellation and Refund Policy</div>
<p className="privacy-content">We cherish every donation and work hard to ensure every rupee is put to work helping others quickly. When we receive your donation, we believe it was your full intention to support our efforts and our initiatives. Because KDP Foundation puts all donations to work quickly, we do not offer refunds on any donations.<br/>
We may offer refunds of received donations in exceptional circumstances by following our donation refund policy mentioned below:<br/>
KDP Foundation has instituted a donation refund policy to ensure fair and transparent processing of requests for a refund. KDP Foundation expects that all donors will exercise due care and diligence while making donations. KDP Foundation also recognizes that a donation may be made erroneously. KDP Foundation will examine request for refund of donation and endeavor to make the refund. KDP Foundation may also seek proper explanation and reason from the user. Also, it will require further verification of the user and need documents of proof as well as for donation.<br/>
Your refund request must contain the details such as Date of Donation, Amount of Donation, Mode of Donation i.e. Credit Card or Online Payment and should reach KDP Foundation office within 7 (Seven) days from the date on which you made the donation i.e.:</p>    
        <ul className="privacypoint"><li>The date on which you Made the donation online, electronically or through other means, OR</li>
         <li>The date on which you Handed over the cheque / demand draft to KDP Foundation or someone authorized by KDP Foundation for this purpose, OR</li>
         <li>The date on which you Dispatched the cheque / demand draft to KDP Foundation by other means.</li></ul>
         
         <p className="privacy-content">The refund process may take time 7 to 30 working days depending on the availability of the information from third parties such as Payment Gateway, Banks, etc. through which transaction is done.<br/>
Refunds are returned using the original method of payment. For example, if you made your donation by credit card, your refund will be credited to that same credit card.<br/>
We reserve the right to refund any received donations after review.</p>
         </div>
         <Footer/>
            
        </div>
    );
}

export default Privacy;