import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import '../Assets/Additionalcss/Header.css'
import Button from './Button';
import donate from '../Assets/Images/donate.png'
import { HashLink } from 'react-router-hash-link';

function Header() {
    const [bars, setbars] = useState(true)
    const [path, setpath] = useState("")
    function sidebar() {
        setbars(!bars)
    }
    useEffect(() => {
        setpath(window.location.hash);
       
      },[path]);
    return (
        <div className="header-body">
        <div>
        <nav className="header-nav" >
            <input type="checkbox" id="check" />
                <label htmlFor="check" className="checkbtn">
                {bars === true ?
                <i className="fas fa-stream" onClick={sidebar}></i>
                :
                <i className="fas fa-times" onClick={sidebar}></i>}
                </label>
            <div className="header-container-fluid">
            <Link className="ul-link" className="navbar-brand logo header-logo" to="/"><span className="LogoName">KDP Foundation</span></Link>
            </div>
            <ul className="header-ul">
            <NavLink className="ul-link " to="/"  ><li  className=   {path==="#/"?"header-list active" :"header-list" }>Home</li></NavLink>
            <NavLink className="ul-link" to="/Whoweare"><li className={path==="#/Whoweare"?"header-list active" :"header-list" }>Who We Are</li></NavLink> 
            <NavLink className="ul-link" to="/Journey"><li className={path==="#/Journey"?"header-list active" :"header-list" }>Journey/Stories</li></NavLink>
            <NavLink className="ul-link" to="/Differentiators"><li className={path==="#/Differentiators"?"header-list active" :"header-list" }>Differentiators</li></NavLink>
            <HashLink className="ul-link" to="/#contribute"><li className={path==="#/Contributors"?"header-list active" :"header-list" }>Contributors</li></HashLink>
            <NavLink className="ul-link" to="/Donate"><li className="header-list">
            <button className="button-29"> Donate Now  &nbsp; <img src={donate} className="donatesymbol"/></button>    
            </li>
            </NavLink>
            </ul>
        </nav>
    </div>   
        </div>
    );
}
export default Header;