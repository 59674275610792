import React from 'react';
import '../Assets/Additionalcss/Footer.css'
import { Link } from "react-router-dom";

function Footer() {
    return (
        <div className="text-center footer">
        <h6 className="footer-contact">CONTACT US</h6>
        <p className="footer-contact-text">80, First Floor, 6th Main, <br/>
        2nd Cross, JP Nagar 3rd Phase, Bengaluru,<br/>
        Karnataka-560078<br/>
        <strong>Phone :</strong> +91 80 26581990<br/>
        <strong>Email :</strong> <a className="email-yellow" href="mailto:contact@kdpfoundation.org">kdpfoundation10@gmail.com</a></p>
        <div className="social-links">
        <a href="https://www.youtube.com/channel/UC_HZqdc_sZKVIG3ifJ-WIkQ?app=desktop"><i className="fab fa-youtube"></i></a> <a href="https://m.facebook.com/Kdpfoundation/"><i className="fab fa-facebook"></i></a> <a href="https://www.instagram.com/1kdpfoundation/"><i className="fab fa-instagram"></i></a> <a href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F65605636%2Fadmin%2F"><i className="fab fa-linkedin"></i></a>
        </div>
        <div className="Footer-body">
        <p className="footer-text">© Copyright 2022 All Rights Reserved | <Link to="/Terms" className="foot">Terms & Conditions</Link> | <Link to="/Privacy" className="foot">Privacy Policy</Link></p>
        </div>
        </div>
    );
}

export default Footer;