import React, { useState, useRef, useEffect } from 'react';
import Axios from 'axios';
import Resizer from "react-image-file-resizer";
import { Image_URL } from '../Config/Base'

function Imageupload() {
    const [sendfiles, setsendfiles] = useState([])
    const [testimg, settestimg] = useState([])
    const [filename, setFilename] = useState([])
    const prevCountRef = useRef([]);
    const[event_id, setEventid] = useState('')
    const[caption, setCaption] = useState('')
    const [data, setData] = useState({
        event_id: "",
        caption: "",
        image_path: ""
    });

    
    const Passingid = (event) => {
            setEventid(event.target.value)
        }
        const Passingcaption = (event) => {
            setCaption(event.target.value)
        }

const imageHandle = (e) =>{
    if(e.target.files){
        const imageArray = Array.from(e.target.files).map((file) => file.name);
        setFilename(imageArray)
    }
}

        const imageHandleChange = (e) => {
            if (e.target.files) {
                setsendfiles([...e.target.files, ...prevCountRef.current])
                const fileArray = Array.from(e.target.files).map((file) => file.name);
                console.log(sendfiles);
            }
        };

        function savetoDB(e) {
            e.preventDefault();
            var bodyFormData = new FormData();
            bodyFormData.append('event_id', event_id);
            bodyFormData.append('caption', caption);
            for (let i = 0; i < sendfiles.length; i++) {
                bodyFormData.append('image_path', sendfiles[i]);
            }
            for (let i = 0; i < sendfiles.length; i++) {
                bodyFormData.append('fileName', sendfiles[i]);
            }

            Axios({
                method: 'post',
                url: 'https://localhost:44346/api/Foundation/AddEventImage',
                headers: {
                    // 'Authorization': Auth,
                    'Content-Type': 'multipart/form-data'
                    //'Content-Type': 'application/json'
                },
                data: bodyFormData
            }).then((res) => {
console.log(res.data)
alert('Ok')
window.location.reload()
    
            }).catch((e) => {
                console.log("error", e);
            })
        }
        useEffect(() => {
            prevCountRef.current = sendfiles;
        }, [sendfiles]);


        return (
            <div>
                <form>
                <input type="text" placeholder="Event_id" value={event_id} onChange={(e) => Passingid(e)}/><br/>
                <input type="file" onChange={imageHandleChange} multiple accept="image/png , image/jpeg , image/webp, image/JPG, image/JPEG, image/jpg"/><br/>
                <input type="text" placeholder="caption"  value={caption} onChange={(e) => Passingcaption(e)}/><br/>
                <input type="file" onChange={imageHandle} multiple/><br/>
                <button onClick={savetoDB}>Submit</button>
                </form>
            </div>
        );
    }

export default Imageupload;