import './App.css';
import React from 'react'
import Home from './Screen/Home';
import { HashRouter,  Route, Routes, useParams} from "react-router-dom";
import Whoweare from './Screen/Whoweare';
import Journey from './Screen/Journey';
import Differentiators from './Screen/Differentiators';
import Terms from './Screen/Terms';
import Privacy from './Screen/Privacy';
import Donate from './Screen/Donate';
import Seemore from './Screen/Seemore';
import Newseemore from './Screen/Newseemore';
import Seemorelist from './Screen/Seemorelist';
import ScrollToTop from './Components/ScrollToTop';
import DistrictEvent from './Screen/DistrictEvent';
import Particularevent from './Screen/Particularevent';
import Imageupload from './Screen/Imageupload';

function App() {
  return (
    <HashRouter>
    <ScrollToTop />
    <Routes>
    <Route exact path="/" element={<Home />} />
    <Route path="/Whoweare" element={<Whoweare />} />
    <Route path="/Journey" element={<Journey />} />
    <Route path="/Differentiators" element={<Differentiators />} />
    <Route path="/Terms" element={<Terms />} />
    <Route path="/Privacy" element={<Privacy />} />
    <Route path="/Donate" element={<Donate />} />
    <Route path="/Seemore/:id" element={<Seemore />} />
    <Route path="/Seemorelist/:id" element={<Seemorelist />} />
    <Route path="/Newseemore/:id" element={<Newseemore />} />
    <Route path="/DistrictEvent" element={<DistrictEvent />} />
    <Route path="/Particularevent/:id" element={<Particularevent />} />
    <Route path="/Imageupload" element={<Imageupload />} />
    </Routes>
  </HashRouter>
  );
}

export default App;