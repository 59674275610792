import React, { useEffect, useMemo, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import '../Assets/Additionalcss/Journey.css'
import Arrow from '../Components/Arrow';
import { useNavigate, generatePath, useLocation } from 'react-router-dom';
import Axios from 'axios';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { BASE_URL } from '../Config/Base'
import { Image_URL } from '../Config/Base'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Journey() {

 
  const navigate = useNavigate();
  const filtertext = useLocation();
  const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
  const district = ['Bangalore', 'Chikkaballapur', 'Chitradurga', 'Davanagere', 'Hassan', 'Kolar', 'Raichur', 'Shimoga', 'Tumkur', 'Tamilnadu', 'Uttar pradesh']
  const [Load, setLoad] = useState(true)
  const [data, setData] = useState([])
  const [filtdata, setFiltdata] = useState([])
  const [intialdata, setIntialData] = useState([])
  const [search, setSearch] = useState('')
  const memoHeader = useMemo(() => <Header />, [])
  const memoFooter = useMemo(() => <Footer />, [])
  const memoFilter = useMemo(() => <div className="Journey-box">
    <div className="container">
      <div className="row">
        <div className="grid-contain">
          {letters.map((item , k) => {
            return <div key={k} className={search == item ? "col grid  active-grid" : "col grid"} onClick={(e) => passdata(item, e)}>{item}</div>
          })}
        </div>
      </div>

    </div>
  </div>, [search])





  const handleProceed = (id, loc) => {
    navigate(generatePath("/Particularevent/:id", { id }), { state: { eletter: search, eMlocation: loc } });
  };
  function passdata(item) {
    setSearch(item)
  }

  useEffect(() => {
    Axios({
      method: "GET",
      url: `${BASE_URL}/Foundation/getJourney`
    }).then((resp) => {
      setData(resp.data)
      setIntialData(resp.data.slice(0, 6))
      jj(resp.data)
    })
    const datas = filtertext.state ? filtertext.state.eletter : "";
    setSearch(datas)
  }, [])

  const jj = (ss) => {


    const disp = ss.filter((e) => {
      return e.event_name.includes('School')
    })
    const wdata = ss.filter((e) => {
      return !e.event_name.includes('School')
    })

    const filt = ss.filter((e) => {
      return e.event_name
    })

    const filteredArr = disp.reduce((acc, current) => {
      const y = acc.find(item => item.event_name === current.event_name && item.event_place === current.event_place);
      if (!y) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    const compain = wdata.concat(filteredArr)
    // const newArray = [];
    // const uniqueObject = {};
    // for (const i in ss) {
    //   const objTitle = ss[i]['event_location'];
    //   uniqueObject[objTitle] = ss[i];
    // }
    // for (const i in uniqueObject) {
    //   newArray.push(uniqueObject[i]);
    // }



    setFiltdata(compain)
    // setFiltdata(ss)
    setLoad(false)
  }

  function val(e, i) {
    navigate(generatePath("/DistrictEvent"), { state: { main_location: i } });
  }


  return (
    <>
      <div className="Journey-container">
        {memoHeader}
        <Arrow />

        {memoFilter}


        <div className="Journey-box-district">
            <div className="grid-contain">
          {district.map((item ,j ) => {
            return <div key={j} className="textcard" onClick={(e) =>val(e, item)}>{item}</div>
          })}
        </div>
      </div>


        {Load == true ?
         <div className="container">
         <div className="row">
           <div className="cards-list">
          <div className="top-skeleton">
            <Stack spacing={15}>
              <div className="skeleton">
                <div className="skeleton-div">
                  <Skeleton variant="rectangular" width={280} height={250} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </div>
                <div className="skeleton-div">
                  <Skeleton variant="rectangular" width={280} height={250} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </div>
                <div className="skeleton-div">
                  <Skeleton variant="rectangular" width={280} height={250} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </div>
              </div>
              <div className="skeleton">
                <div className="skeleton-div">
                  <Skeleton variant="rectangular" width={280} height={250} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </div>
                <div className="skeleton-div">
                  <Skeleton variant="rectangular" width={280} height={250} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </div>
                <div className="skeleton-div">
                  <Skeleton variant="rectangular" width={280} height={250} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </div>
              </div>
            </Stack>
          </div>
          </div>
          </div>
          </div>
          : search == "" ?
            <>
                    <div className="container">
        <div className="row">
          <div className="cards-list">
            {intialdata.map((item , l) => {
              return <><div key={l} className="journeycard" onClick={(e) => {
                handleProceed(item.Id);
              }}>
                <div className="journey-date">
                  <p className="journey-dat">
                    <span className="journey-da">{new Date(item.event_date).getDate()}</span><br />{new Date(item.event_date).toLocaleString('default', { month: 'short' })}</p>
                </div>
                <LazyLoadImage
                className="card-img"
    alt={`${Image_URL}${item.image_path}`}
    effect="blur"
    src={`${Image_URL}${item.image_path}`} />
                <div className="card-body">
                  <h6 className="card-title"><b>{item.event_name}</b> at {item.event_place}</h6>
                  <div onClick={(e) => {
                    handleProceed(item.Id);
                  }}>See More</div>
                </div>
              </div>
              </>
            })}
            </div>
            </div>
            </div>
            </>
            :
            <>
<div className="container">
<div className="row">
              {filtdata.filter((item) => {
                if (search == 0) {
                  return item
                }
                else if (search == item.event_place.slice(0, 1)) {
                  return item
                }
              }).length == 0 ? <div className="journeycard">
                <div className="card-body">
                  <h6 className="card-title"><b>No Event</b></h6>
                </div>
              </div> : filtdata.filter((item) => {
                if (search == 0) {
                  return item
                }
                else if (search == item.event_place.slice(0, 1)) {
                  return item
                }
              }).map((item, e, m) => {
                return <>

<div key={m} className="journeycard" onClick={(e) => {
          handleProceed(item.Id);
        }}>
          <div className="journey-date">
            <p className="journey-dat">
              <span className="journey-da">{new Date(item.event_date).getDate()}</span><br />{new Date(item.event_date).toLocaleString('default', { month: 'short' })}</p>
          </div>
          <LazyLoadImage
          className="card-img"
    alt={`${Image_URL}${item.image_path}`}
    effect="blur"
    src={`${Image_URL}${item.image_path}`} />
          <div className="card-body">
            <h6 className="card-title"><b>{item.event_name}</b> at {item.event_place}</h6>
            <div onClick={(e) => {
              handleProceed(item.Id);
            }}>See More</div>
          </div>
        </div>


                </>
              }
              )}
</div>
</div>

            </>

        }
      </div>

      {memoFooter}
    </>
  );
}
export default Journey;